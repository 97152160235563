const WNCharLimitCounter = (props: WNCharLimitCounterProps) => {
  const { charLength, charLimit } = props;

  const charLimitExceeded =
    charLimit && (charLength === charLimit ? 'char-limit-exceeded' : '');

  return (
    <p
      className={'char-limit-count ' + charLimitExceeded}
    >{`(${charLength}/${charLimit})`}</p>
  );
};

export default WNCharLimitCounter;
