import { useSelector } from 'react-redux';
import AuthHeadingComponent from '../components/HeadingComponents/AuthHeadingComponent';
import HomeHeadingComponent from '../components/HeadingComponents/HomeHeadingComponent';

const Heading = (props: HeadingProps) => {
  const { headingText } = props;

  const whichUserFlow = useSelector(
    (state: AppState) => state.nav.whichUserFlow
  );

  switch (whichUserFlow) {
    case 'auth-flow':
      return <AuthHeadingComponent headingText={headingText} />;
    case 'home-flow':
      return <HomeHeadingComponent headingText={headingText} />;
    default:
      return <HomeHeadingComponent headingText={headingText} />;
  }
};

export default Heading;
