import WNCharLimitCounter from './WNCharLimitCounter';

const WNInput = (props: WNInputProps) => {
  const {
    id,
    label,
    placeholder,
    value,
    optionalClass,
    onBlur,
    onChange,
    charLimit,
  } = props;

  const isPassword = () => {
    return label.toLocaleLowerCase().includes('password');
  };

  return (
    <div className="input-wrapper">
      <div className="input-label-group">
        <label htmlFor={label} className="input-label">
          {label}
        </label>
        {charLimit && <WNCharLimitCounter charLength={value.length} charLimit={charLimit}/>}
      </div>
      <input
        id={id}
        value={value}
        placeholder={placeholder}
        aria-label={label}
        className={'body2 wilderNet-input ' + optionalClass}
        type={isPassword() ? 'password' : 'text'}
        autoComplete={label}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={charLimit}
      />
    </div>
  );
};

export default WNInput;
