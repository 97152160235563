import { useEffect, useState } from 'react';

const SuccessModal = (props: SuccessModalProps) => {
  const {
    show,
    successText,
    buttonOneText,
    buttonTwoText = null,
    buttonThreeText = null,
    handleFirstButtonClick,
    handleSecondButtonClick = null,
    handleThirdButtonClick = null,
  } = props;
  const [showModelClass, setShowModalClass] = useState('hide-modal');

  useEffect(() => {
    if (show) {
      setShowModalClass('');
    } else {
      setShowModalClass('hide-modal');
    }
  }, [show]);

  const greenCheckMark = (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <circle
        className="path circle"
        fill="none"
        stroke="#486332"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        className="path check"
        fill="none"
        stroke="#486332"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );

  return (
    <div
      className={`success-modal-overlay fade ${showModelClass}`}
      id="statusSuccessModal"
    >
      <div className="success-modal" role="document">
        {greenCheckMark}
        <h4 className="text-success">Success!</h4>
        <p>{successText}</p>
        <div className="modal-button-container">
          <button className="" onClick={handleFirstButtonClick}>
            {buttonOneText}
          </button>
          {handleSecondButtonClick && buttonTwoText && (
            <button onClick={handleSecondButtonClick}>{buttonTwoText}</button>
          )}
          {handleThirdButtonClick && buttonThreeText && (
            <button onClick={handleThirdButtonClick}>{buttonThreeText}</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
