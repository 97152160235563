import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import BlogsPreview from '../../components/BlogCardComponents/BlogsPreview';
import WNButton from '../../components/FormComponents/WNButton';
import WNInput from '../../components/FormComponents/WNInput';
import WNInputErrorMessage from '../../components/FormComponents/WNInputErrorMessage';
import Backdrop from '../../hoc/Backdrop';
import Heading from '../../hoc/Heading';
import SuccessModal from '../../hoc/SuccessModal';
import { resetUserState } from '../../store/reducers/userReducer';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialEmail = useSelector((state: AppState) => state.user.email);

  const [emailInput, setEmailInput] = useState(initialEmail);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [axiosError, setAxiosError] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const handleEmailInputOnChange = (event: any) => {
    setEmailInput(event.target.value);
  };

  const handleForgetEmail = async (event: any) => {
    event.preventDefault();
    setButtonDisabled(true);
    setAxiosError('');
    try {
      await axios
        .post('/api/auth/users/reset_password/', {
          email: emailInput,
        })
        .then(() => {
          setShowSuccessModal(true);
          resetUserState();
          setAxiosError('');
        })
        .catch((e: any) => {
          if (e?.response?.data) {
            const data = e.response.data;
            let err = '';
            if (data instanceof Object) {
              const items = Object.values(data).flat();
              if (items instanceof Array) {
                err = items[0] as string;
              }
            } else if (typeof data === 'string') {
              err = data as string;
            }
            setAxiosError(`Error: ${err}`);
          } else {
            setAxiosError(`An error occured (${e.response.status})`);
          }
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    } catch (e: any) {
      setAxiosError(`An error occured`);
      setButtonDisabled(false);
    }
  };

  const handleContinue = () => {
    dispatch(resetUserState());
    navigate('/login-or-signup');
  };

  return (
    <div className="login-page">
      <div className="login-signup-component">
        <Heading headingText="Forgot your password?" />
        <p className="body1">
          Enter your email address and we'll send you a link to reset your
          password.
        </p>
        <form name="login-form" className="login-signup-form">
          <WNInput
            id={'login-email-input'}
            label={'Your Email'}
            placeholder={''}
            value={emailInput}
            onChange={handleEmailInputOnChange}
          />
          <WNInputErrorMessage errorMessage={axiosError} />
          <WNButton
            buttonText="Send password email"
            onClick={handleForgetEmail}
            disabled={buttonDisabled}
          />
          <SuccessModal
            show={showSuccessModal}
            successText={`An email has been sent to ${emailInput} with further instructions.`}
            buttonOneText="Continue"
            handleFirstButtonClick={handleContinue}
          />
          <Backdrop show={showSuccessModal} />
        </form>
      </div>
      <BlogsPreview />
    </div>
  );
};

export default ForgotPasswordPage;
