import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  sendDeleteRequest,
  sendGetRequest,
  sendPostRequest,
} from './requestUtil';

// This returns a single blog by ID, with a list of discussions & reviews
export const getBlogDetailsById = createAsyncThunk(
  'get-blog-details-by-id',
  async (blogId: string | number | undefined): Promise<BlogCard> => {
    return sendGetRequest(`/api/blogs/${blogId}/`);
  }
);

export const getBlogReviewsById = createAsyncThunk(
  'get-blog-reviews-by-id',
  async (
    blogId: string | number | undefined
  ): Promise<BlogReviewsListSuccessType> => {
    return sendGetRequest(`/api/blogs/${blogId}/reviews/`);
  }
);

export const getBlogDiscussionById = createAsyncThunk(
  'get-blog-discussion-by-id',
  async (
    blogId: string | number | undefined
  ): Promise<BlogDiscussionsListSuccessType> => {
    return sendGetRequest(`/api/blogs/${blogId}/discussions/`);
  }
);

export const verifyBlogUrl = createAsyncThunk(
  'verifyBlogUrl-blog-url',
  async (url: string): Promise<BlogCard> => {
    return sendGetRequest(`/api/blogs/url/?url=${url}`);
  }
);

export const uploadBlogImage = createAsyncThunk(
  'upload-blog-image',
  async (body: FormData, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/blogs/upload-image/', body, token);
  }
);

export const uploadEditBlog = createAsyncThunk(
  'upload-blog',
  async (uploadBlogData: UploadBlogRequestType, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/upload-edit-blog/', uploadBlogData, token);
  }
);

export const saveBlogBookmark = createAsyncThunk(
  'save-blog-bookmark',
  async (body: any, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/blog/bookmark/', body, token);
  }
);

export const deleteBookmark = createAsyncThunk(
  'delete-bookmark',
  async (body: any, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendDeleteRequest(
      `/api/blog/bookmark/?blogId=${body.blogId}`,
      token
    );
  }
);
