import { useSelector } from 'react-redux';
import { JSX } from 'react/jsx-runtime';
import BlogCardLarge from './BlogCardLarge';

const BlogsPreview = () => {
  const blogs = useSelector((state: AppState) => state.blogList.shuffled.blogs);

  let topBlogs: JSX.Element[] = [];
  let bottomBlogs: JSX.Element[] = [];
  blogs.forEach((blog, index) => {
    if (index % 2 === 0) {
      topBlogs.push(
        <BlogCardLarge
          key={blog.title}
          tag_limit={3}
          isPresentational={true}
          {...blog}
        />
      );
    } else {
      bottomBlogs.push(
        <BlogCardLarge
          key={blog.title}
          tag_limit={3}
          isPresentational={true}
          {...blog}
        />
      );
    }
  });

  return (
    <div className="blogs-preview">
      <div className="scroll-container">
        <div className="scroll-content__top">{topBlogs}</div>
      </div>

      <h1 className="blog-preview-message">
        Reconnect with the Internet's
        <br />
        Authentic Side
      </h1>

      <div className="scroll-container">
        <div className="scroll-content__bottom">{bottomBlogs}</div>
      </div>
    </div>
  );
};

export default BlogsPreview;
