import { createSlice } from '@reduxjs/toolkit';

import { getBlogByProfileTags } from '../../actions/blogListActions';
import { blogListDefaultCases, initBlogListType } from './blogListReducerUtils';

const initialState = initBlogListType() as BlogListStateType;

// * create slice state
const blogProfileTagListSlice = createSlice({
  name: 'blogProfileTagListSlice',
  initialState,
  reducers: {
    resetProfileBlogListState: () => initialState,
  },
  extraReducers: builder => {
    blogListDefaultCases(builder, getBlogByProfileTags);
  },
});

export const { resetProfileBlogListState } = blogProfileTagListSlice.actions;
export default blogProfileTagListSlice.reducer;
