import { InfoOutlined } from '@mui/icons-material';

const WNInputErrorMessage = (props: WNInputErrorMessagePropType) => {
  const { errorMessage } = props;
  return (
    <div
      className="input-error-message"
      style={{
        visibility: errorMessage && '' !== errorMessage ? 'visible' : 'hidden',
      }}
    >
      <InfoOutlined
        sx={{
          fontSize: '15px',
          marginRight: '5px',
        }}
      />
      {errorMessage}
    </div>
  );
};

export default WNInputErrorMessage;
