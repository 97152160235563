import storage from 'redux-persist/lib/storage';
import blogShuffledListSlice from './reducers/blogLists/blogShuffledListReducer';
import blogPageSlice from './reducers/blogPageReducer';
import navSlice from './reducers/navigationReducer';
import searchSlice from './reducers/searchReducer';
import tagSlice from './reducers/tagReducer';
import userSlice from './reducers/userReducer';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import blogProfileTagListReducer from './reducers/blogLists/blogProfileTagListReducer';
import blogRecentListReducer from './reducers/blogLists/blogRecentListReducer';

const rootReducer = combineReducers({
  user: userSlice,
  tag: tagSlice,
  blogList: combineReducers({
    shuffled: blogShuffledListSlice,
    recent: blogRecentListReducer,
    profileTag: blogProfileTagListReducer,
  }),
  blogPage: blogPageSlice,
  nav: navSlice,
  searchResults: searchSlice,
});

const persistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const STORE_VERSION = '2.2.0';

export const persistor = persistStore(store, null, () => {
  const currentVersion = localStorage.getItem('reduxVersion');

  if (currentVersion !== STORE_VERSION) {
    persistor.purge();
    localStorage.setItem('reduxVersion', STORE_VERSION);
  }
});
