import { createSlice } from '@reduxjs/toolkit';

import { getBlogShuffledList } from '../../actions/blogListActions';
import { blogListDefaultCases, initBlogListType } from './blogListReducerUtils';

const initialState = initBlogListType() as BlogListStateType;

// * create slice state
const blogShuffledListSlice = createSlice({
  name: 'blogShuffledListSlice',
  initialState,
  reducers: {
    resetShuffledBlogListState: () => initialState,
  },
  extraReducers: builder => {
    blogListDefaultCases(builder, getBlogShuffledList);
  },
});

export const { resetShuffledBlogListState } = blogShuffledListSlice.actions;
export default blogShuffledListSlice.reducer;
