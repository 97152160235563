import MenuIcon from '@mui/icons-material/Menu';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setIsSideDrawerOpen } from '../../store/reducers/navigationReducer';
import { resetUserState } from '../../store/reducers/userReducer';

const HomeHeadingComponent = (props: HeadingProps) => {
  const { headingText } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state: AppState) => state.user.isLoggedIn);

  const handleLoginClick = () => {
    navigate('/login-or-signup');
  };

  const handleLogoutClick = () => {
    dispatch(resetUserState());
    navigate('/home');
  };

  const getAuthHeadingComponent = () => {
    if (isLoggedIn) {
      return (
        <button className="logout-button" onClick={handleLogoutClick}>
          Logout
        </button>
      );
    } else {
      return (
        <button className="login-nav-button" onClick={handleLoginClick}>
          Login
        </button>
      );
    }
  };

  const useLogo = useMemo(() => {
    return (
      headingText.toLocaleLowerCase() === 'The WilderNet'.toLocaleLowerCase()
    );
  }, [headingText]);

  return (
    <div className="home-heading-wrapper">
      <div className="hamburger-menu-wrapper">
        <MenuIcon onClick={() => dispatch(setIsSideDrawerOpen(true))} />
      </div>
      {useLogo ? (
        <img
          src="/assets/images/Logo.png"
          alt="The Wildernet"
          className="home-heading-image"
        />
      ) : (
        <h3 className="home-heading-text">{headingText}</h3>
      )}
      <h3 className="login-logout-heading-button">
        {getAuthHeadingComponent()}
      </h3>
    </div>
  );
};

export default HomeHeadingComponent;
