import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

const AuthHeadingComponent = (props: HeadingProps) => {
  const navigate = useNavigate();
  const { headingText } = props;

  return (
    <div className="auth-heading-wrapper">
      <IconButton onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <h3>{headingText}</h3>
    </div>
  );
};

export default AuthHeadingComponent;
