import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router';
import TagGroup from '../Tag Components/TagGroup';

const BlogCardLarge = (props: BlogCard) => {
  const {
    id,
    title,
    description,
    tags,
    slug,
    image_blog_page,
    isPresentational,
    tag_limit = 12,
  } = props;
  const navigate = useNavigate();

  const handleClickBlogCard = async (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isPresentational) navigate(`/blog/${slug}/${id}`);
  };

  return (
    <Card
      className="blog-card-large"
      key={title}
      sx={{ boxShadow: '3px 2px 8px 4px rgba(0, 0, 0, 0.2)' }}
      onClick={handleClickBlogCard}
    >
      <CardActionArea className="blog-card-action-area">
        <CardMedia
          component="img"
          image={image_blog_page}
          className="blog-card-img"
        />
        <CardContent className="blog-card-content">
          <h5>{title}</h5>
          <p>{description}</p>
          <TagGroup
            tagList={tags}
            tagLimit={tag_limit}
            isPresentational={isPresentational}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BlogCardLarge;
