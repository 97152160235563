const WilderNetLogo = (props?: any) => {

  const { customClass } = props;

  const logoClass = `logo-wrapper ${customClass}`;

  return <div className={logoClass} ></div>;
};

export default WilderNetLogo;
