
import WNLogo from '../components/WNLogo';




const AboutPage = () => {
  return (
    <div style={{ padding: '20px' }} className="body1">
      <WNLogo customClass={'about'} />
      <h1>About The WilderNet</h1>
      <p>
        Once upon a time, the internet was a wild, amazing place where people
        from around the world could build community,
        discover solutions to their problems, and exchange ideas.
        Then, everything changed when corporations took over and
        turned the internet into a commercial hellscape. </p>

      <p>
        We want to carve out a space on the internet by real people,
        for real people. The cool little sites that made the internet
        special still exist - but they are buried underneath a
        mountain of ads and corporate slop. </p>

      <p>
        We need your help to build a site so independent voices can be discovered again!
        Here are the ways you can support us: </p>

      <p>
        1) Upload your favorite blogs to the site!
        It doesn’t matter how active the blogs are or if they are super old.
        We welcome vintage content! Add a short description, and some subject
        tags to make it easier for likeminded people to find. </p>

      <p>
        2) Donate! Your donations go towards our talented team of developers to 
        make the site better, and towards paying the bills. </p>

      <p>
        Help us keep the internet wild for all!
      </p>
      <p>
        Thank you for being a part of our community!
      </p>
    </div>
  );
};

export default AboutPage;