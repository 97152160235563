import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { searchBlogs } from '../../store/actions/searchActions';
import { setSearchQuery } from '../../store/reducers/searchReducer';
import { store } from '../../store/store';

type TagItemProps = {
  tagItem: string;
  isPresentational: boolean;
};

const TagItem = (props: TagItemProps) => {
  const { tagItem, isPresentational } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickTag = async (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isPresentational) {
      e.stopPropagation();
      dispatch(setSearchQuery(tagItem));
      await store.dispatch(searchBlogs({ query: tagItem }));
      navigate('/search-results');
      window.location.reload();
    }
  };

  const tagClass = isPresentational
    ? 'interest-card'
    : 'interest-card clickable';

  return (
    <div key={tagItem} className={tagClass} onClick={handleClickTag}>
      {tagItem}
    </div>
  );
};

export default TagItem;
