const WNButton = (props: WNButtonProps) => {
  const {
    isSecondary,
    isFullWidth,
    optionalClass,
    buttonText,
    buttonStyles,
    disabled,
    onClick,
    isSubmit,
  } = props;

  const buttonClass = isSecondary ? 'button-secondary' : 'button-primary';
  const buttonWidthClass = isFullWidth ? 'button-full-width' : '';

  return (
    <button
      className={`${buttonClass} ${buttonWidthClass} ${optionalClass}`}
      style={buttonStyles}
      onClick={onClick}
      disabled={disabled}
      type={isSubmit ? 'submit' : 'button'}
    >
      {buttonText}
    </button>
  );
};

export default WNButton;
